import type { LoginCredentials } from '~/types/application'
import { defineStore } from 'pinia'

export const useAuthStore = defineStore('useAuthStore', () => {
    const nuxtApp = useNuxtApp()
    const route = useRoute()

    const { signIn, signOut, refresh, getSession, token } = useAuth()
    const { status, data } = useAuthState()
    const refreshTries = ref(0)

    const hasBeenAuthenticated = ref(false)

    const authenticated = computed(() => {
        if (status.value === 'authenticated' && token.value) {
            hasBeenAuthenticated.value = true
            return true
        }

        // If we're loading and were previously authenticated, stay authenticated
        if (status.value === 'loading' && hasBeenAuthenticated.value)
            return true

        return false
    })

    const account = computed(() => data.value ?? null)
    const hasScango = computed(() => account.value?.features.includes('scan_and_go') ?? false)
    const loading = computed(() => status.value === 'loading')

    const becameUnauthenticated = computed(() => status.value === 'unauthenticated')
    const becameAuthenticated = computed(() => status.value === 'authenticated')

    const clearTokens = () => {
        return nuxtApp.runWithContext(() => {
            const accessToken = useCookie('auth.token')
            const refreshToken = useCookie('auth.refresh-token')
            const runtimeConfig = useRuntimeConfig()

            accessToken.value = null
            refreshToken.value = null

            // Ensure cookies are removed with proper path and domain
            if (import.meta.client) {
                const domain = runtimeConfig.public.env === 'dev' ? 'localhost' : 'demegro.nl'
                document.cookie = `auth.token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`
                document.cookie = `auth.refresh-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`
            }
        })
    }

    const refreshNuxt = async () => {
        try {
            await nuxtApp.runWithContext(async () => {
                await refreshNuxtData()
            })
        } catch (error) {
            console.error('RefreshNuxt: Error refreshing data', error)
            throw error
        }
    }

    const clearNuxt = async () => {
        try {
            await nuxtApp.runWithContext(async () => {
                clearNuxtData()
            })
        } catch (error) {
            console.error('ClearNuxt: Error clearing data', error)
            throw error
        }
    }

    const clearAndRefreshNuxt = async () => {
        try {
            await clearNuxt()
            await refreshNuxt()
        } catch (error) {
            console.error('ClearAndRefreshNuxt: Error during clear and refresh', error)
            throw error
        }
    }

    const handleSession = async () => {
        try {
            const session = await getSession()
            if (session)
                return session

            await handleRefresh()

            return await getSession()
        } catch {
            clearTokens()
        }
    }

    const handleLogin = async (credentials: LoginCredentials) => {
        // Get redirect URL from query parameters
        const redirectUrl = route.query.redirect?.toString() || route.path

        await signIn(credentials, {
            redirect: false,
            callbackUrl: redirectUrl,
        })

        await refreshNuxt()
    }

    const resetAuthenticationState = () => {
        hasBeenAuthenticated.value = false
    }

    const handleLogout = async () => {
        try {
            // Sign out without redirect
            await signOut({ redirect: false })
            clearTokens()
            resetAuthenticationState()

            useBroadcast().broadcastLogout()

            // Force clear any remaining fetch caches
            await refreshNuxt()
        } catch {
            // Even if there's an error, try to clean up
            resetAuthenticationState()
            await clearAndRefreshNuxt()
        }
    }

    const handleLasLogin = async () => {
        // Only run on client-side
        if (import.meta.server)
            return

        // Get redirect URL from query parameters
        const redirectUrl = route.query.redirect?.toString() || route.path
        const las = route.query.las?.toString()

        if (!las) {
            return
        }

        const session = await getSession()

        if (session) {
            // Sign out without redirect
            await signOut({ redirect: false })
            resetAuthenticationState()

            useBroadcast().broadcastLogout()
        }

        const credentials = {
            las,
        }

        await signIn(credentials, {
            redirect: false,
            callbackUrl: redirectUrl,
        })

        if (session) {
            clearAndRefreshNuxt()
        } else {
            await refreshNuxt()
        }
    }

    const handleRefresh = async () => {
        // check if there are any tokens in the cookies
        const accessToken = useCookie('auth.token')
        const refreshToken = useCookie('auth.refresh-token')

        if (!accessToken.value || !refreshToken.value) {
            return
        }

        if (refreshTries.value > 3) {
            console.info('AuthStore: Too many refresh tries, logging out')
            await handleLogout()
            return
        }

        try {
            refreshTries.value++
            if (await refresh()) {
                refreshTries.value = 0
            }
        } catch (error) {
            await handleLogout()
        }
    }

    return {
        authenticated,
        account,
        becameUnauthenticated,
        becameAuthenticated,
        handleLasLogin,
        handleLogin,
        handleLogout,
        handleRefresh,
        handleSession,
        clearAndRefreshNuxt,
        clearNuxt,
        refreshNuxt,
        hasScango,
        loading,
        resetAuthenticationState,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
