import { defineNuxtPlugin } from '#app'

/**
 * Clear all keys in the Nuxt payload data that contain the given match string,
 * except for those keys which exactly match any of the items in the exceptions array.
 *
 * @param matchKey - The partial string used to match keys.
 * @param exceptions - An array of key names that should not be deleted.
 */
function clearNuxtDataByPartialKey(matchKey: string, exceptions: string[] = []) {
    const nuxtApp = useNuxtApp()
    if (nuxtApp.payload && nuxtApp.payload.data) {
        Object.keys(nuxtApp.payload.data).forEach((key) => {
            if (key.includes(matchKey) && !exceptions.includes(key)) {
                delete nuxtApp.payload.data[key]
            }
        })
    }
}

export default defineNuxtPlugin(() => {
    return {
        provide: {
            clearNuxtDataByPartialKey,
        },
    }
})
