<template>
    <NuxtLoadingIndicator color="#0891b2" />
    <NuxtLayout>
        <NuxtPage />
        <UiFeedback v-if="authStore.authenticated" />
    </NuxtLayout>
</template>

<script setup lang="ts">
import { appDescription, appName } from '~/constants'

navigator?.serviceWorker?.getRegistrations()?.then((registrations) => {
    for (const registration of registrations) {
        registration.unregister()
    }
})

useHead({
    title: appName,
    meta: [{ name: appName, content: appDescription }],
})

useSeoMeta({
    title: appName,
    ogTitle: appName,
    ogImage: 'https://nieuw.demegro.nl/favicon.png',
    ogUrl: 'https://nieuw.demegro.nl',
    ogDescription: appDescription,
})

const authStore = useAuthStore()
await authStore.handleLasLogin()
await authStore.handleSession()

useBroadcast()
</script>

<style>
html,
body,
#__nuxt {
    height: 100vh;
    margin: 0;
    padding: 0;
}

html.dark {
    background: #222;
    color: white;
}
</style>
