<template>
    <Transition
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform -translate-y-2 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition duration-200 ease-in"
        leave-from-class="transform translate-y-0 opacity-100"
        leave-to-class="transform -translate-y-2 opacity-0"
    >
        <div v-show="isVisible" :key="animationKey">
            <div
                class="flex animate-ping-once items-center space-x-2 rounded-lg bg-lime-100 px-4 py-3 text-sm font-bold text-white"
                :class="[classes.bg, classes.border, classes.border ? 'border-2' : '']"
                role="alert"
            >
                <font-awesome-icon :icon="['fas', 'info-circle']" :class="classes.text" class="flex-shrink-0" />
                <p :class="classes.text">
                    {{ message }}
                </p>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import type { SetColors } from '~/constants/colors'
import type { AlertOptions } from '~/types/application'
import { defaultColors } from '~/constants/colors'

const message = ref('')
const classes = ref<SetColors>(defaultColors)
const isVisible = ref(false)
const animationKey = ref(0)
let timeout: NodeJS.Timeout

/**
 * The show method allows parent components to trigger the alert dynamically.
 * This approach encapsulates the alert logic within the component, making it
 * easy to use without managing state externally.
 */
function show(options: AlertOptions) {
    if (!options.colors)
        options.colors = defaultColors

    clearTimeout(timeout)
    message.value = options.message
    classes.value = options.colors
    isVisible.value = true

    // Increment key to force re-render so pinging animation happens each time
    animationKey.value++

    timeout = setTimeout(() => {
        isVisible.value = false
    }, options.duration ?? 3000)
}

defineExpose({ show })

onUnmounted(() => {
    clearTimeout(timeout)
})
</script>
