<template>
    <!-- Fixed container for both button and form -->
    <div class="fixed bottom-4 right-4 z-50 flex flex-col items-end space-y-4">
        <!-- Feedback form -->
        <Transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="transform translate-y-2 opacity-0"
            enter-to-class="transform translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="transform translate-y-0 opacity-100"
            leave-to-class="transform translate-y-2 opacity-0"
        >
            <div
                v-if="isOpen"
                ref="formContainer"
                class="rounded-lg bg-white shadow-xl"
                :class="{
                    'w-[350px]': !isComponentActive,
                    'w-[550px]': isComponentActive,
                    'transition-all duration-200': !isResizing,
                }"
                :style="{ height: `${formHeight}px` }"
            >
                <!-- Resize handle -->
                <div class="resize-handle" @mousedown="startResize">
                    <div class="resize-bar" />
                </div>

                <div class="p-6 h-full flex flex-col">
                    <div class="mb-6 text-center">
                        <h3 class="text-xl font-bold text-gray-800">
                            Feedback
                        </h3>
                        <div class="divider" />
                        <p class="text-sm text-gray-600 leading-relaxed">
                            Laat ons weten wat je vindt van de nieuwe website!
                        </p>
                    </div>

                    <textarea v-model="feedbackMessage" class="feedback-input" placeholder="Voer hier uw feedback in..." />

                    <!-- Button container -->
                    <div class="flex flex-row items-center gap-4 mt-2">
                        <div class="flex-1">
                            <UiAlert ref="alert" />
                        </div>

                        <button class="submit-button relative" :disabled="isSubmitting" @click="handleSubmit">
                            <span :class="{ 'opacity-0': isSubmitting || showSuccess }">
                                Verstuur feedback
                            </span>

                            <!-- Loading spinner -->
                            <span v-if="isSubmitting" class="absolute inset-0 flex items-center justify-center">
                                <font-awesome-icon
                                    :icon="['fad-sharp', 'spinner-third']"
                                    class="animate-spin h-5 w-5 text-white"
                                />
                            </span>

                            <!-- Success checkmark -->
                            <span v-if="showSuccess" class="absolute inset-0 flex items-center justify-center text-white">
                                <font-awesome-icon :icon="['fas', 'check']" class="h-5 w-5" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </Transition>

        <button class="feedback-button" @click="isOpen = !isOpen">
            <font-awesome-icon :icon="['fas', 'comment-exclamation']" class="text-xl" />
        </button>
    </div>
</template>

<script lang="ts" setup>
import html2canvas from 'html2canvas'
import { colors } from '~/constants/colors'

const alert = useTemplateRef('alert')

const isOpen = ref(false)
const feedbackMessage = ref('')
const formHeight = ref(400) // Default height
const isResizing = ref(false)
const formContainer = ref<HTMLElement | null>(null)
const isComponentActive = ref(false)
const isSubmitting = ref(false)
const showSuccess = ref(false)

async function captureScreenshot(): Promise<string | null> {
    try {
        // // Hide the feedback form temporarily
        // if (formContainer.value) {
        //     formContainer.value.style.display = 'none'
        // }

        const options = {
            logging: false,
        }

        const captureFullPage = false

        if (!captureFullPage) {
            // Get the viewport dimensions for visible screen capture
            const viewportWidth = window.innerWidth
            const viewportHeight = window.innerHeight
            const scrollX = window.scrollX
            const scrollY = window.scrollY

            Object.assign(options, {
                windowWidth: viewportWidth,
                windowHeight: viewportHeight,
                x: scrollX,
                y: scrollY,
                width: viewportWidth,
                height: viewportHeight,
            })
        }

        // Capture the screenshot based on the selected mode
        const canvas = await html2canvas(document.body, options)
        const screenshot = canvas.toDataURL('image/jpeg', 0.7) // Using JPEG for smaller size

        // // Show the form again
        // if (formContainer.value) {
        //     formContainer.value.style.display = ''
        // }

        return screenshot
    } catch (error) {
        console.error('Failed to capture screenshot:', error)
        return null
    }
}

function startResize(event: MouseEvent) {
    const startY = event.clientY
    const startHeight = formHeight.value
    isResizing.value = true
    document.body.classList.add('resize-active')

    function handleResize(event: MouseEvent) {
        if (!isResizing.value || !formContainer.value)
            return
        const deltaY = startY - event.clientY
        formContainer.value.style.height = `${Math.min(Math.max(startHeight + deltaY, 300), 800)}px`
    }

    function stopResize() {
        if (!isResizing.value || !formContainer.value)
            return
        formHeight.value = Number.parseInt(formContainer.value.style.height)
        isResizing.value = false
        document.body.classList.remove('resize-active')
        document.removeEventListener('mousemove', handleResize)
        document.removeEventListener('mouseup', stopResize)
    }

    document.addEventListener('mousemove', handleResize)
    document.addEventListener('mouseup', stopResize)
}

const authStore = useAuthStore()

async function sendFeedback(message: string, screenshotData: string | null) {
    try {
        const user = authStore.account
            ? {
                    fullname: authStore.account.fullname,
                    company_name: authStore.account.company_name,
                    email: authStore.account.email,
                    debtor_number: authStore.account.debtor_number,
                    phone: authStore.account.phone,
                }
            : null

        await $fetch('/api/content/feedback', {
            method: 'POST',
            body: {
                message,
                user,
                data: screenshotData,
            },
        })

        alert.value?.show({ message: 'Feedback verzonden!', colors: colors.success })
    } catch (error) {
        console.error('Feedback submission failed:', error)
        alert.value?.show({ message: 'Feedback verzenden mislukt', colors: colors.danger })
    }
}

async function handleSubmit() {
    if (!feedbackMessage.value.trim() || isSubmitting.value) {
        alert.value?.show({
            message: 'Er is nog geen feedback ingevuld',
            colors: colors.warning,
        })
        return
    }

    isSubmitting.value = true
    showSuccess.value = false

    try {
        const screenshot = await captureScreenshot()
        await sendFeedback(feedbackMessage.value, screenshot)
        showSuccess.value = true
        setTimeout(() => {
            showSuccess.value = false
        }, 2000)

        feedbackMessage.value = ''
        setTimeout(() => {
            isOpen.value = false
        }, 2000)
    } catch (error) {
        alert.value?.show({ message: 'Feedback verzenden mislukt', colors: colors.danger })
    } finally {
        isSubmitting.value = false
    }
}

function handleClickOutside(event: MouseEvent) {
    if (formContainer.value && !formContainer.value.contains(event.target as Node)) {
        isComponentActive.value = false
    } else if (formContainer.value && formContainer.value.contains(event.target as Node)) {
        isComponentActive.value = true
    }
}

onMounted(() => {
    document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside)
})
</script>

<style scoped>
.resize-handle {
    @apply h-1.5 w-full cursor-ns-resize transition-colors mt-2;
}

.resize-bar {
    @apply mx-auto h-full w-16 rounded-full hover:bg-gray-400 bg-gray-200;
}

.divider {
    @apply my-3 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent;
}

.feedback-input {
    @apply flex-1 w-full rounded-lg border border-gray-200 p-3 text-gray-700 placeholder-gray-400 transition-colors focus:border-blue-demegro focus:outline-none focus:ring-1 focus:ring-blue-demegro resize-none;
}

.submit-button {
    @apply rounded-lg bg-blue-demegro px-6 py-2 text-white transition-all hover:bg-cyan-700 disabled:opacity-75 disabled:cursor-not-allowed min-w-[160px] h-[46px];
}

.feedback-button {
    @apply flex h-12 w-12 items-center justify-center rounded-full bg-blue-demegro text-white shadow-lg transition-all hover:bg-cyan-700 hover:shadow-xl;
}

:global(.resize-active) {
    user-select: none;
}
</style>
