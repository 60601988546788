import revive_payload_client_4sVQNw7RlN from "/builds/brixion/demegro/demegro-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/brixion/demegro/demegro-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/brixion/demegro/demegro-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/brixion/demegro/demegro-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/brixion/demegro/demegro-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/brixion/demegro/demegro-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/brixion/demegro/demegro-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/builds/brixion/demegro/demegro-frontend/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/brixion/demegro/demegro-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_I4dbrL2rYz from "/builds/brixion/demegro/demegro-frontend/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import fontawesome_eFtMTV32gl from "/builds/brixion/demegro/demegro-frontend/app/plugins/fontawesome.ts";
import sessionRefresh_zdvogy6Y8s from "/builds/brixion/demegro/demegro-frontend/app/plugins/sessionRefresh.ts";
import headlessui_sjlhjitz3Q from "/builds/brixion/demegro/demegro-frontend/app/plugins/headlessui.ts";
import clearNuxtDataByPartialKey_CtclXjX5An from "/builds/brixion/demegro/demegro-frontend/app/plugins/clearNuxtDataByPartialKey.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  plugin_I4dbrL2rYz,
  fontawesome_eFtMTV32gl,
  sessionRefresh_zdvogy6Y8s,
  headlessui_sjlhjitz3Q,
  clearNuxtDataByPartialKey_CtclXjX5An
]